import React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import CTA from "../components/cta-block";

import Layout from "../components/layout";
import SEO from "../components/common/SEO/";
import Hero from "../components/common/Hero/PageHero";
import styled from "styled-components"

const Content = styled(Row)`
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const shortcodes = { Row, Col, CTA };

const PageTemplate = ({ pageContext, data: { mdx } }) => {
  let currentPage = mdx;
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  let parent = currentPage.frontmatter.parent?.frontmatter.title ? currentPage.frontmatter.parent.frontmatter.title : "home";
  
  return (
    <Layout pageId={currentPage.slug}>
      <SEO title={currentPage.frontmatter.title} />
      <Hero parent={parent} title={currentPage.frontmatter.title} />
      <Container>
        <Row>
          <Col>
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator=" » "
              crumbLabel={
                currentPage.slug === "reach-awards"
                  ? "Celebrate Those Who Reach Farther"
                  : currentPage.title
              }
            />
          </Col>
        </Row>
        <Content>
          <Col>
            <MDXProvider components={shortcodes}>
              <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </Col>
        </Content>
      </Container>

    </Layout>
  );
};
  
export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        parent {
          frontmatter {
            title
          }
        }
      }
      id
      body
      slug
    }
  }
`;
